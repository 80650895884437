import React from 'react';

const Spinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-white bg-opacity-100 flex justify-center items-center z-50">
      <div className="border-4 border-t-4 border-blue-gray-500 rounded-md w-12 h-12 animate-spin"></div>
    </div>
  );
};

export default Spinner;