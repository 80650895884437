import React, { Suspense, lazy, useState } from "react";
import { Route, Routes } from "react-router-dom";
import './App.css'
import Spinner from "./components/spinner";

// Lazy load components
const AdvanceSearch = lazy(() => import("./pages/advance-search"));
const ForgotPass = lazy(() => import("./pages/auth/forgot-pass"));
const SignIn = lazy(() => import("./pages/auth/sign-in"));
const Register = lazy(() => import("./pages/auth/sign-up"));
const ActivateUser = lazy(() => import("./pages/auth/activate-user"));
const ContactUS = lazy(() => import("./pages/contact-us"));
const Faq = lazy(() => import("./pages/faq"));
const Home = lazy(() => import("./pages"));
const ByAuthor = lazy(() => import("./pages/explore/by-author"));
const KeywordsIndex = lazy(() => import("./pages/explore/keyword-index"));
const BySubject = lazy(() => import("./pages/explore/by-subject"));
const CurrentIssue = lazy(() => import("./pages/explore/current-issue"));
const OuterIndexing = lazy(() => import("./pages/explore/outer-indexing"));
const SiteMap = lazy(() => import("./pages/explore/site-map"));
const AboutJournal = lazy(() => import("./pages/journal-info/about-journal"));
const ReviewersPage = lazy(() => import("./pages/journal-info/reviewers"));
const AimsAndScope = lazy(() => import("./pages/journal-info/aims-and-scope"));
const PublicationEthics = lazy(() => import("./pages/journal-info/publication-ethics"));
const PeerReview = lazy(() => import("./pages/journal-info/peer-review"));
const JournalStatistics = lazy(() => import("./pages/journal-info/journal-statistics"));
const GuideForAuthors = lazy(() => import("./pages/journal-info/guide-for-authors"));
const ArticlesInJournal = lazy(() => import("./pages/articles-in-journal"));
const ArticlesList = lazy(() => import("./pages/articles-list"));
const SubmitManuscript = lazy(() => import("./pages/submit-manuscript"));
const IssueDetail = lazy(() => import("./pages/issue"));
const EditorialBoard = lazy(() => import("./pages/journal-info/our-team"));
const MyNews = lazy(() => import("./pages/news"));
const Article = lazy(() => import("./pages/article"));
const NewsDetail = lazy(() => import("./pages/news/detail"));
const AppLayout = lazy(() => import("./layouts/appLayout"));
const AdminRoute = lazy(() => import("./routes/adminRoute"));
const EditorInChiefRoute = lazy(() => import("./routes/editorInChiefRoute"));
const EditorRoute = lazy(() => import("./routes/editorRoute"));
const ManagerRoute = lazy(() => import("./routes/managerRoute"));
const ReviewerRoute = lazy(() => import("./routes/reviewerRoute"));
const JudgeRoute = lazy(() => import("./routes/judgeRoute"));
const UserRoute = lazy(() => import("./routes/userRoute"));
const SearchResult = lazy(() => import("./pages/search-result"));
const NotAuthenticator = lazy(() => import("./components/not_authenticated"));
const AuthenticatedRoute = lazy(() => import("./components/authenticator"));
const RelatedLinks = lazy(() => import("./pages/journal-info/relatedLink"));
const ByVolume = lazy(() => import("./pages/explore/by-volume"));
const TrackManuscript = lazy(() => import("./pages/track-manuscript"));



const ProtectedRoute = ({ element, allowedRoles }) => (
  <AuthenticatedRoute allowedRoles={allowedRoles}>
    {element}
  </AuthenticatedRoute>
);

export default function App() {
  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }
  return (
    <>
      {loading && <Spinner />}
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route
            path="/admin/*"
            element={<ProtectedRoute element={<AdminRoute />} allowedRoles={['admin']} />}
          />
          <Route
            path="/editor-in-chief/*"
            element={<ProtectedRoute element={<EditorInChiefRoute />} allowedRoles={['editorInChief']} />}
          />
          <Route
            path="/editor/*"
            element={<ProtectedRoute element={<EditorRoute />} allowedRoles={['technicalEditor', 'literaryEditor']} />}
          />
          <Route
            path="/manager/*"
            element={<ProtectedRoute element={<ManagerRoute />} allowedRoles={['manager']} />}
          />
          <Route
            path="/reviewer/*"
            element={<ProtectedRoute element={<ReviewerRoute />} allowedRoles={['reviewer']} />}
          />
          <Route
            path="/judge/*"
            element={<ProtectedRoute element={<JudgeRoute />} allowedRoles={['judge']} />}
          />
          <Route
            path="/user/*"
            element={<ProtectedRoute element={<UserRoute />} allowedRoles={['scholar']} />}
          />
          <Route element={<AppLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/track-manuscript" element={<TrackManuscript />} />

            <Route path="articles" element={<ArticlesInJournal />} />
            <Route path="/article" element={<Article />} />
            <Route path="/advance-search" element={<AdvanceSearch />} />
            <Route path="/search-result" element={<SearchResult />} />
            <Route path="/issue" element={<IssueDetail />} />
            <Route path="/auth">
              <Route path="forgot-pass" element={<NotAuthenticator><ForgotPass /></NotAuthenticator>} />
              <Route path="sign-in" element={<NotAuthenticator><SignIn /></NotAuthenticator>} />
              <Route path="sign-up" element={<NotAuthenticator><Register /></NotAuthenticator>} />
              <Route path="activate/:code" element={<ActivateUser />} />
            </Route>
            <Route path="/contact-us" element={<ContactUS />} />
            <Route path="/explore">
              <Route path="keyword-index" element={<KeywordsIndex />} />
              <Route path="keyword-index/articles" element={<ArticlesList />} />
              <Route path="by-author" element={<ByAuthor />} />
              <Route path="by-author/articles" element={<ArticlesList />} />
              <Route path="by-subject" element={<BySubject />} />
              <Route path="by-subject/articles" element={<ArticlesList />} />
              <Route path="by-volume" element={<ByVolume />} />
              <Route path="current-issue" element={<CurrentIssue />} />
              <Route path="outer-indexing" element={<OuterIndexing />} />
              <Route path="site-map" element={<SiteMap />} />
            </Route>
            <Route path="/faq" element={<Faq />} />
            <Route path="/reviewers" element={<ReviewersPage />} />
            <Route path="/journal-info">
              <Route path="about-journal" element={<AboutJournal />} />
              <Route path="editorial-board" element={<EditorialBoard />} />
              <Route path="aims-and-scope" element={<AimsAndScope />} />
              <Route path="publication-ethics" element={<PublicationEthics />} />
              <Route path="peer-review" element={<PeerReview />} />
              <Route path="journal-statistics" element={<JournalStatistics />} />
              <Route path="guide-for-authors" element={<GuideForAuthors />} />
              <Route path="related-links" element={<RelatedLinks />} />
            </Route>
            <Route path="submit-manuscript" element={<SubmitManuscript />} />
            <Route path="/news">
              <Route index element={<MyNews />} />
              <Route path="detail" element={<NewsDetail />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}
